import React,{useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";
import Board from './Component/Board';

//crud
import { getIotDeviceList, postIotDeviceHistoryList, getIotDeviceErrorList } from 'crud/auth.crud';

const ErrorMain = () => {

  const [deviceList, setDeviceList] = useState([])
  const [filteredDeviceList, setFilteredDeviceList] = useState([])
  const [statusList, setStatusList] = useState([])
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('id')
  const [page, setPage] = useState(1);
  const [centerMarker, setCenterMarker] = useState({ lat: 36.44699, lng: 127.09598 })
  const [screenList, setScreenList] = useState(['0', false])
  const [isLoading, setIsLoading] = useState([false, false, false])

  const [selectDevice, setSelectDevice] = useState('')


  // useEffect( async () => {
  //   await getErrorDeviceList()
  // }, [page])

  //단말 목록
  // const getErrorDeviceList = async (page, type, search) => {
  //   setIsLoading(prevState => [!prevState[0], !prevState[1], prevState[2]])
  //   setStatusList([])

  //   // console.log("error list gettttt")
  //   try {
  //     let params = {
  //       search: search || '',
  //       page: page || 1,
  //       type : type || '', 
  //       order : order
  //     }
  //     if(params.type === ''){
  //       params.type = 'company'
  //     }
  //     const response = await getIotDeviceErrorList(params)
  //     if (response.status === 200) {
  //       // console.log(response)
  //       const content = response.data?.data || []
  //       createMarker(content)
  //       if (content.length > 0) {
  //         content.forEach((item, index) => {
  //           content[index]['id'] = content[index]['id'].toString()
  //         })
  //         setFilteredDeviceList([...content])
  //         setDeviceList([...content])
  //         setStatusList([...content])
  //       } else {
  //         setFilteredDeviceList([])
  //         setDeviceList([])
  //         setStatusList([])
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error)
  //   }
  //   setIsLoading(prevState => [!prevState[0], !prevState[1], prevState[2]])
  // };

  //단말 목록
  const getDeviceList = async () => {
    setIsLoading(prevState => [!prevState[0], !prevState[1], prevState[2]])
    setStatusList([])
    try {
      const response = await getIotDeviceList()
      if (response.status === 200) {
        let content = response.data?.data || []

        console.log("errorrr list ...",content)

        if (content.length > 0) {
          content.forEach((item, index) => {
            content[index]['id'] = content[index]['id'].toString()
          })

          let filteredContent = content.filter(i => i.error_type !== null && i.error_type !== '');
          let content1 = filteredContent.filter(i => i.id.indexOf(search) !== -1)
          let content2 = filteredContent.filter(i => i.company.name.indexOf(search) !== -1)
          let content3 = filteredContent.filter(i => i.address !== null ? i.address.indexOf(search) !== -1 : '')
          filteredContent = Array.from(new Set([...content1, ...content2, ...content3])).sort()
          createMarker(filteredContent)
          setFilteredDeviceList([...filteredContent])
          setDeviceList([...filteredContent])
        } else {
          setFilteredDeviceList([])
          setDeviceList([])
        }
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(prevState => [!prevState[0], !prevState[1], prevState[2]])
  };

  // 단말 위치 마커 및 검색 단말
  const createMarker = (arr) => {
    let lat = []
    let lng = []
    for(let i=0;i<arr.length;i++){
      lat.push(arr[i].lat)
      lng.push(arr[i].lon)
    }
    const center = {lat: (Math.max(...lat)+Math.min(...lat))/2, lng: (Math.max(...lng)+Math.min(...lng))/2}

    //대한민국을 벗어나거나 오류값일경우 마커를 센터에 찍음.
    if(center.lat > 38.3640 || center.lat < 33.0643 || center.lng > 124.3636 || center.lng < 131.5210 || (center.lat).isNaN() || (center.lng).isNaN()) {
      center.lat = 36.44699
      center.lng = 127.09598
    }

    if(centerMarker.lat === center.lat && centerMarker.lng === center.lng){
      center.lat -= 0.00001
      center.lng -= 0.00001
      setCenterMarker(center)
    }else{
      setCenterMarker(center)
    }
  }

  const callback = async(id) => {
    setIsLoading(prevState => [prevState[0], prevState[1], !prevState[2]])
    try{
      const response = await postIotDeviceHistoryList({iot_device_id : id, page: 1})
      if (response.status === 200) {
        const content = response.data?.results
        console.log("134",content)
        setSelectDevice(id)
        if (content.length > 0) {
          setStatusList(response.data?.results)
        } else {
          setStatusList([])
        }
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(prevState => [prevState[0], prevState[1], !prevState[2]])
  }

  const filterDeviceList = (type, searchText) => {
    setFilteredDeviceList(deviceList.filter(i => i[type].indexOf(searchText) !== -1))
  }

  const filterList = (type, searchText, domKey) => {
    switch (domKey) {
      case '2':
        filterDeviceList(type, searchText)
        break
      default:
        null
    }
  }

  const fullScreen = (key) => {
    if(screenList[0] === key){
      setScreenList(['0', false])
    }else {
      setScreenList([key, true])
    }
  }

  useEffect(async ()=>{
    await getDeviceList()
  },[])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <div className="position-relative app-search d-none d-lg-block" style={{ padding: "calc(18px / 2) 0" }}>
            <input
              // type="text"
              className="form-control"
              placeholder="검색어를 입력해주세요..."
              onChange={(e) => {
                setSearch(e.target.value.length == 0 ? '' : e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  getDeviceList()
                }
              }}
            />
            <span onClick={ () => {
              getDeviceList()
            }} className="fa fa-search" style={{ lineHeight: '56px', cursor: 'pointer' }} />
          </div>
          <Row>
            <Col xl={screenList[0] !== '1' ? 4 : 12} lg={screenList[0] !== '1' ? 8 : 12} style={(screenList[0] !== '1' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'단말 위치'}
                form={'map'}
                dataList={filteredDeviceList} 
                callback={callback} 
                boardKey={'device'}
                height={screenList[0] !== '1' ? '85vh' : '125vh'}
                centerMarker={centerMarker}
                domKey={'1'}
                fullScreen={fullScreen}
                isLoading={isLoading[0]} />
            </Col>
            <Col xl={screenList[0] !== '2' ? 8 : 12} style={(screenList[0] !== '2' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'검색 단말'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN', class: 'device-id'},
                  {key : 'order_company', value: '발주처'},
                  {key : 'address', value: '지역'},
                  {key : 'construct_at', value: '설치일'},
                  {key : 'sign_content', value: '표지판 내용'},
                  {key : 'signage_standard', value: '표지판 규격'},
                  {key : 'project_name', value: '공사명'},
                  {key : 'fixErr', value: '확인'}
                ]}
                dataList={filteredDeviceList} 
                callback={callback} 
                boardKey={'device'}
                height={screenList[0] !== '2' ? '85vh' : '125vh'}
                domKey={'2'}
                fullScreen={fullScreen}
                isLoading={isLoading[1]}
                filterList={filterList} />
            </Col>
            <Col xl={12} style={(screenList[0] !== '3' && screenList[1]) ? { display: 'none' } : {}}>
              <Board
                title={'단말 상태 - '+selectDevice}
                form={'post'}
                headers={[
                  { key: 'updated_at', value: '이벤트시간', class: 'current-id' },
                  { key: 'error_type', value: '에러 타입' },
                  { key: 'event_type', value: 'LED상태' },
                  { key: 'solar_voltage', value: '쏠라전압' },
                  { key: 'solar_reference_voltage', value: '쏠라기준전압' },
                  { key: 'battery_voltage', value: '배터리전압' },
                  { key: 'channel_output', value: 'LED 총전류값' },
                  // { key: 'digital_2', value: 'digital_2' },
                  // { key: 'digital_12', value: 'digital_12' },
                  // { key: 'pwm_dim_13', value: 'pwm_13' },
                  // { key: 'pwm_dim_14', value: 'pwm_14' },
                  // { key: 'voltage_pv', value: 'Voltage_pv' },
                  // { key: 'voltage_bat', value: 'Voltage_bat' },
                  // { key: 'led01', value: 'led 1' },
                  // { key: 'led02', value: 'led 2' },
                  // { key: 'led03', value: 'led 3' },
                  // { key: 'led04', value: 'led 4' },
                  // (이벤트시간, LED상태, 쏠라전압, 쏠라기준전압, 배터리전압, LED 총전류값)
                ]}
                dataList={statusList} 
                callback={callback} 
                boardKey={'device'}
                height={screenList[0] !== '3' ? '85vh' : '125vh'}
                domKey={'3'}
                fullScreen={fullScreen}
                isLoading={isLoading[2]} />
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default ErrorMain
